import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const Testimonial = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Updates!!',
    paragraph: 'BootCamp 1 is already going on, stay tuned for the next batch by joining the telegram group.',
    link: 'https://t.me/+vNiPooMf04lmNTk1'
  };

  const nanoCourses = {
    title: 'Free Material!!',
    paragraph: 'Refer these modules from Nano courses written by us based on our experiences',
    contents: [
      {
        name: "Module 1",
        link: "https://twitter.com/indian_quant/status/1470459286413213700?s=20&t=sp5Y9kPgLVY0SVFT5pPJ5A"
      },
      {
        name: "Module 2",
        link: "https://twitter.com/indian_quant/status/1484755533059923976?s=20&t=sp5Y9kPgLVY0SVFT5pPJ5A"
      },
      {
        name: "Module 3",
        link: "https://twitter.com/indian_quant/status/1492100561528778754?s=20&t=sp5Y9kPgLVY0SVFT5pPJ5A"
      },
      {
        name: "Module 3B",
        link: "https://twitter.com/indian_quant/status/1495467753842434049?s=20&t=sp5Y9kPgLVY0SVFT5pPJ5A"
      },
      {
        name: "Module 4",
        link: "https://twitter.com/indian_quant/status/1500369095463940101?s=20&t=sp5Y9kPgLVY0SVFT5pPJ5A"
      },
      {
        name: "Module 5A",
        link: "https://twitter.com/indian_quant/status/1519388453393223680?s=20&t=sp5Y9kPgLVY0SVFT5pPJ5A"
      },
    ]
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <SectionHeader data={nanoCourses} className="center-content" />
          {
            nanoCourses.contents &&
            <div style={{textAlign: "center"}}>
              <ul style={{display: "inline-block", textAlign: "left"}}>
                {
                  nanoCourses.contents.map((item, index) => {
                    return (
                      <li>
                        <span>
                          <a href={item.link}>{item.name}</a>
                        </span>
                      </li>
                    )
                  })
                }
              </ul>
            </div>
          }
          <div className={tilesClasses}>

            {/* <div className="tiles-item reveal-from-right" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                    — Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum cillum dolore eu fugiat.
                      </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">Roman Level</span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    <a href="#0">AppName</a>
                  </span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                    — Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum cillum dolore eu fugiat.
                      </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">Diana Rynzhuk</span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    <a href="#0">AppName</a>
                  </span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-left" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                    — Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum cillum dolore eu fugiat.
                      </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">Ben Stafford</span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    <a href="#0">AppName</a>
                  </span>
                </div>
              </div>
            </div> */}

          </div>
        </div>
      </div>
    </section>
  );
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;